import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  StyleRules,
  Tab,
  Tabs,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { companyGetAllProject } from '../../actions/projectProduceAction';
import DialogTitle from '../../components/DialogTitle';
import { AppState } from '../../reducers';
import { companyMenuAction } from '../../reducers/companyMenuReducer';
import { formatDate } from '../../utils/dateFormat';
import PendingProjectChangeView from './PendingProjectChangeView';
import PendingProjectComment from './PendingProjectComment';
import PendingProjectDetailsView from './PendingProjectDetailsView';

const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      minHeight: '80vh',
      padding: '20px',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      height: '625px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },

    avatar: {
      borderRadius: '50%',
      width: '3.5rem',
      height: '3.5rem',
      margin: '0',
      '&:hover': {
        boxShadow: '0px 4px 16px 3px rgba(0,0,0,0.2)',
      },
    },
    listBox: {
      padding: '1rem',
      border: '1px solid #ccc',
    },
    verticalLine: {
      margin: '0 0.5rem',
      color: '#ccc',
    },
    h3: {
      margin: '1rem 0',
      textAlign: 'center',
    },
    list: {
      background: '#eee',
    },
    listContainer: {
      padding: '1.25rem',
      height: 'calc(625px - 72px)',
      overflowY: 'scroll',
    },
    listItem: {
      padding: '1.25rem',
      borderRadius: '1rem',
      background: '#fff',
      marginBottom: '1.25rem',
      position: 'relative',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    itemTop: {
      display: 'flex',
      alignItems: 'center',
    },
    projectName: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#333',
      marginRight: '1rem',
    },
    statusTag: {
      width: '3.5rem',
      height: '1.25rem',
      textAlign: 'center',
      lineHeight: '1.25rem',
      fontSize: '0.75rem',
      color: '#ff4d4f',
      border: '1px solid #ff4d4f',
      marginRight: '2rem',
      borderRadius: '0.6rem',
    },
    salary: {
      color: '#242f65',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
    },
    spread: {
      background: '#f4f4f5',
      borderBottomLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      padding: '0.35rem',
      textAlign: 'center',
      fontSize: '0.75rem',
      position: 'absolute',
      top: 0,
      right: 0,
      color: '#7c8087',
      display: 'flex',
      alignItems: 'center',
    },

    itemMiddle: {
      margin: '1.25rem 0',
      display: 'flex',
      alignItems: 'center',
    },
    middleDescTag: {
      background: '#f4f4f5',
      textAlign: 'center',
      lineHeight: '1.5rem',
      color: '#4c4f54',
      fontSize: '0.75rem',
      padding: '0.2rem 0.5rem',
      marginRight: '0.5rem',
    },
    itemBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    bottomLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '2rem',
      height: '2rem',
      marginBottom: 0,
    },
    companyName: {
      color: '#333',
      fontWeight: 'bold',
      margin: '0 0.75rem 0 0.5rem',
      display: 'flex',
      alignItems: 'center',
      // fontSize: '1.5rem',
    },
    text: {
      fontSize: '0.75rem',
      color: '#7c8087',
      marginLeft: '0.5rem',
    },
    delivery: {
      fontSize: '1rem',
      color: '#242f65',
      fontWeight: 'bold',
      '&:hover': {
        textShadow: '0px 4px 16px 0px rgba(0,0,0,0.2)',
      },
    },
    projectStartTime: {
      color: '#7c8087',
      fontSize: '0.75rem',
      display: 'flex',
      alignItems: 'center',
    },
  });

const pendingProjectListView = ({ classes }: Props): ReactElement => {
  const dispatch = useDispatch();
  const projectProduce = useSelector((appState: AppState) => appState.projectProduce);
  const [value, setValue] = useState(0);
  const [statusTitle, setStatusTitle] = useState('进行中项目');
  const [projectInfoData, setProjectInfoData] = useState<ProjectInfo[]>([]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [showOpen, setShowOpen] = useState(false);
  const handleClose = () => {
    setShowOpen(false);
  };

  // const handleOpen = () => {
  //   setShowOpen(true);
  // };

  const [editOpen, setEditOpen] = React.useState(false);
  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const [showCommentOpen, setShowCommentOpen] = React.useState(false);

  const handleCommentClose = () => {
    setShowCommentOpen(false);
  };

  const handleCommentOpen = () => {
    setShowCommentOpen(true);
  };

  useEffect(() => {
    filterData();
    switch (value) {
      case 0:
        setStatusTitle('进行中项目');
        break;
      case 1:
        setStatusTitle('终止项目');
        break;
      case 2:
        setStatusTitle('已完成项目');
        break;
    }
  }, [value]);

  const fetchData = async () => {
    await dispatch(companyGetAllProject());
  };

  const filterData = (): void => {
    if (projectProduce.projectProduceInfo.length > 0) {
      switch (value) {
        case 0: {
          const projectData = projectProduce.projectProduceInfo.filter(
            item => item.status == 'IN_PROGRESS' || item.status == 'INITIATED'
          );
          setProjectInfoData(projectData);
          break;
        }
        case 1: {
          const projectData = projectProduce.projectProduceInfo.filter(
            item => item.status == 'SUSPEND'
          );
          setProjectInfoData(projectData);
          break;
        }
        case 2: {
          const projectData = projectProduce.projectProduceInfo.filter(
            item => item.status == 'COMPLETED'
          );
          setProjectInfoData(projectData);
          break;
        }
      }
    }
  };

  useEffect(() => {
    filterData();
  }, [projectProduce]);

  useEffect(() => {
    fetchData();
    dispatch(
      companyMenuAction({
        menuStatus: {
          home: false,
          publishProject: false,
          projectList: false,
          biddingList: false,
          pendingProject: true,
        },
      })
    );
  }, []);

  return (
    <>
      <Helmet title="进行中项目"></Helmet>
      <Grid container className={classes.contentPadding} justifyContent="space-between">
        <Paper square className={classes.childrenItem}>
          <Tabs
            variant="fullWidth"
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="进行中" icon={<LinearScaleIcon />} />
            <Tab label="终止项目" icon={<PausePresentationIcon />} />
            <Tab label="已完成" icon={<CheckCircleOutlineIcon />} />
          </Tabs>
          <div className={classes.list}>
            <div className={classes.listContainer}>
              {projectInfoData.map((item: ProjectInfo) => {
                return (
                  <div className={classes.listItem} key={item.id}>
                    <div className={classes.itemTop}>
                      <div className={classes.projectName}>
                        <AssignmentIcon />
                        项目：
                        {item.offer?.offerName}
                      </div>
                      <span className={classes.verticalLine}></span>
                      <div className={classes.salary}>
                        项目酬劳 {item.offer?.projectRemuneration}
                      </div>
                      <div className={classes.spread}>
                        <ScheduleIcon fontSize="small" />
                        {formatDate(
                          new Date(`${item.offer?.projectStartTime}`),
                          'yyyy-MM-dd'
                        )} - {formatDate(new Date(`${item.offer?.projectEndTime}`), 'yyyy-MM-dd')}
                      </div>
                    </div>

                    <p></p>
                    <div className={classes.itemBottom}>
                      <div className={classes.bottomLeft}>
                        <span className={classes.companyName}>
                          <PersonIcon />
                          顾问：
                          {item.offer?.bid
                            ? `${item.offer?.bid.consultant.verification.basicInfo.firstName}${item.offer?.bid.consultant.verification.basicInfo.lastName}`
                            : null}
                        </span>
                        <span className={classes.text}>完成进度</span>
                        <span className={classes.text}>
                          <CircularProgress variant="determinate" value={75} size="1.5rem" />
                        </span>
                      </div>
                      <div className={classes.bottomRight}>
                        <Button
                          size="small"
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => {
                            setShowOpen(true);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                          查看
                        </Button>
                        <Button
                          size="small"
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.buttonMargin}
                          onClick={() => {
                            setShowCommentOpen(true);
                          }}
                        >
                          <ChatBubbleOutlineIcon fontSize="small" /> 评价
                        </Button>

                        {value == 0 && (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="small"
                              className={classes.buttonMargin}
                              onClick={() => {
                                setEditOpen(true);
                              }}
                            >
                              <EditIcon fontSize="small" /> 更改
                            </Button>
                          </>
                        )}
                        {value != 2 && (
                          <Button
                            size="small"
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.buttonMargin}
                            onClick={() => {
                              // setEditOpen(true);
                            }}
                          >
                            <LocalAtmIcon fontSize="small" /> 结算
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
      </Grid>

      <Dialog onClose={handleClose} open={showOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {statusTitle}
        </DialogTitle>
        <DialogContent dividers>
          <PendingProjectDetailsView
            onClose={handleClose}
            onCommentOpen={handleCommentOpen}
            editOpen={handleEditOpen}
            statusValue={value}
          ></PendingProjectDetailsView>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleCommentClose} open={showCommentOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleCommentClose}>
          <AssignmentIcon /> 项目评价
        </DialogTitle>
        <DialogContent dividers>
          <PendingProjectComment onClose={handleCommentClose}></PendingProjectComment>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleEditClose} open={editOpen} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleEditClose}>
          <AssignmentIcon /> 更改项目
        </DialogTitle>
        <DialogContent dividers>
          <PendingProjectChangeView></PendingProjectChangeView>
        </DialogContent>
      </Dialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(pendingProjectListView);
